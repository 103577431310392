// PrivacyPolicy.js
import React from "react";

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: "20px", textAlign: "center" }}>
      <h1>Privacy Policy</h1>
      <p>
        You can view our Privacy Policy below or{" "}
        <a
          href="https://rememberpad.s3.us-east-1.amazonaws.com/notes/RememberPad_Privacy_Policy.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          download the PDF
        </a>
        .
      </p>
      <iframe
        src="https://rememberpad.s3.us-east-1.amazonaws.com/notes/RememberPad_Privacy_Policy.pdf"
        width="100%"
        height="800px"
        style={{ border: "none" }}
        title="Privacy Policy"
      ></iframe>
    </div>
  );
};

export default PrivacyPolicy;
