import React, { useEffect, useState } from "react";
import {
  Box,
  Input,
  Text,
  VStack,
  Button,
  Image,
  Flex,
  Tag,
} from "@chakra-ui/react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import AddNoteButton from "./AddNoteButton";
import NoteCard from "./NoteCard";
import { useLocation } from "react-router-dom";
import { rankNotes } from "./helper";

// API Gateway URL
const API_URL =
  "https://bwkgm6mfhx2fjgedkjkslvlcx40qlqql.lambda-url.us-east-1.on.aws/notes";

// Function to fetch notes from the API
const fetchNotes = async () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("token"),
  };
  const { data } = await axios.get(API_URL, { headers });
  return data;
};

// Function to create a new note
const createNote = async (newNote) => {
  const { data } = await axios.post(API_URL, newNote, {
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

const FolderNotes = () => {
  const [search, setSearch] = useState("");
  const queryClient = useQueryClient();

  const path = useLocation();
  console.log(path.pathname);

  // Fetch notes using React Query
  const [notes, setNotes] = useState([]);
  const [folderName, setFolderName] = useState("");

  const rankedNotes = rankNotes(notes, search);

  // Create note using React Query mutation
  const mutation = useMutation(createNote, {
    onSuccess: () => {
      queryClient.invalidateQueries(["notes"]); // Refetch notes after mutation
    },
  });

  const addTextNote = () => {
    const newNote = {
      title: `New Text Note ${notes ? notes.length + 1 : 1}`,
      content: `This is a new text note`,
    };
    mutation.mutate(newNote);
  };

  const addImageNote = () => {
    const newNote = {
      title: `New Image Note ${notes ? notes.length + 1 : 1}`,
      content: `This is a new image note`,
    };
    mutation.mutate(newNote);
  };

  useEffect(() => {
    if (localStorage) {
      if (localStorage.getItem("current_folder_notes")) {
        setNotes(JSON.parse(localStorage.getItem("current_folder_notes")));
      }
      if (localStorage.getItem("current_folder_name")) {
        setFolderName(localStorage.getItem("current_folder_name"));
      }
    }
  }, []);

  return (
    <Box w="100vw" h="100vh" bg="gray.100" p={4}>
      {/* Top search bar */}
      <Input
        size={"lg"}
        borderRadius={"2xl"}
        placeholder="Search notes..."
        mb={4}
        bg="white"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
      />

      <Flex gap="2" flexWrap={"wrap"} mb="4">
        <Tag
          size="lg"
          variant={path?.pathname === "/notes" ? "solid" : "outline"}
          onClick={() => {
            document.location.href = "/notes";
          }}
          cursor={"pointer"}
          _hover={{ bg: "gray.200" }}
        >
          All
        </Tag>
        <Tag
          size="lg"
          variant={path?.pathname === "/folders" ? "solid" : "outline"}
          onClick={() => {
            document.location.href = "/folders";
          }}
          cursor={"pointer"}
          _hover={{ bg: "gray.200" }}
        >
          Categories
        </Tag>
        <Tag size="lg" variant={"solid"}>
          {folderName}
        </Tag>
      </Flex>

      {/* Check if there are no notes */}
      {notes && notes.length === 0 ? (
        <VStack spacing={4} align="center" h="80vh" justify="center">
          <Image src="/empty.png" alt="No notes" boxSize="150px" />
          <Text fontSize="lg" color="gray.500">
            No saved notes found. Add a note!
          </Text>
        </VStack>
      ) : (
        // Middle text boxes for notes
        <VStack
          spacing={4}
          align="stretch"
          mb={16} // Add margin for the button at the bottom
          h="80vh"
          overflowY="auto"
        >
          {rankedNotes &&
            rankedNotes.map((note) => <NoteCard key={note.id} note={note} />)}
        </VStack>
      )}

      <AddNoteButton onAddText={addTextNote} onAddImage={addImageNote} />

      {/* Loading and error handling for mutations */}
      {mutation.isLoading && <div>Creating note...</div>}
      {mutation.error && (
        <div>Error creating note: {mutation.error.message}</div>
      )}
    </Box>
  );
};

export default FolderNotes;
