import AddTextNote from "./AddTextNote";
import NotesApp from "./Dashboard";
import { Route, Switch } from "react-router-dom";
import Home from "./Home";
import NoteDisplay from "./NoteDisplay";
import Folders from "./Folders";
import FolderNotes from "./FolderNotes";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfService from "./TermsOfService";
import EditTextNote from "./EditNote";
import ShareHandler from "./ShareHandler";

function App() {
  return (
    <Switch>
      <Route path="/privacy-policy">
        <PrivacyPolicy />
      </Route>
      <Route path="/tos">
        <TermsOfService />
      </Route>
      <Route path="/note/create">
        <AddTextNote />
      </Route>
      <Route path="/notes">
        <NotesApp />
      </Route>
      <Route path="/folders">
        <Folders />
      </Route>
      <Route path="/folder/:folderId">
        <FolderNotes />
      </Route>
      <Route path="/note/view">
        <NoteDisplay />
      </Route>
      <Route path="/note/edit">
        <EditTextNote />
      </Route>
      <Route path="/share-handler">
        <ShareHandler />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  );
}

export default App;
