import React, { useState } from "react";
import {
  Box,
  Text,
  Image,
  Grid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Flex,
  Tag,
} from "@chakra-ui/react";

const NoteCard = ({ note }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (event, image) => {
    event.stopPropagation(); // Prevents the card's onClick from firing
    setSelectedImage(image);
    onOpen();
  };

  return (
    <Box
      p={4}
      bg="white"
      borderRadius="3xl"
      shadow="sm"
      onClick={() => {
        localStorage.setItem("note", JSON.stringify(note));
        document.location.href = "/note/view";
      }}
      _hover={{ cursor: "pointer", shadow: "md", backgroundColor: "cyan.50" }}
    >
      <Box>
        <Text fontWeight="bold">{note.title}</Text>
        <Text dangerouslySetInnerHTML={{ __html: note.content }} />
      </Box>

      <Grid templateColumns="repeat(3, 1fr)" gap={4} mt={4}>
        {note.images?.map((image, index) => (
          <Image
            key={index}
            src={image.content} // Assuming base64 images
            alt={`note-image-${index}`}
            boxSize="100px"
            objectFit="cover"
            onClick={(e) => handleImageClick(e, image)}
            cursor="pointer"
            borderRadius={"md"}
          />
        ))}
      </Grid>

      {note.tags && note.tags.length > 0 ? (
        <Flex gap="2" flexWrap={"wrap"} my="4">
          {note.tags.map((tag, index) => (
            <Text key={index} bg="gray.200" px={2} py={1} borderRadius="md">
              {tag}
            </Text>
          ))}
        </Flex>
      ) : (
        <Tag my="2" colorScheme="purple">
          Processing
        </Tag>
      )}

      {selectedImage && (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent maxW="90%">
            <ModalBody p="0">
              <Image
                borderRadius={"md"}
                src={selectedImage.content}
                alt="expanded-image"
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default NoteCard;
