import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  VStack,
  Flex,
  SimpleGrid,
  Text,
  Tag,
  HStack,
  Button,
  Icon,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import { FiCalendar, FiDelete, FiEdit } from "react-icons/fi";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import { GoCopy } from "react-icons/go";
import { PiMagicWandBold } from "react-icons/pi";
// Mock Note Data (replace with actual data or props)

const NoteDisplay = () => {
  const toast = useToast();
  const [note, setNote] = useState(null);

  useEffect(() => {
    if (localStorage) {
      const tempNote = JSON.parse(localStorage.getItem("note"));
      if (tempNote) {
        setNote(tempNote);
      }
    }
  }, []);

  const handleReminderNotification = () => {
    toast({
      title: "Reminder set.",
      description: `You have a reminder on ${new Date(
        note.reminder_datetime
      ).toLocaleString()}.`,
      status: "info",
      duration: 5000,
      isClosable: true,
      position: "top",
    });
  };

  const handleDeleteNote = async () => {
    try {
      await axios.delete(
        `https://bwkgm6mfhx2fjgedkjkslvlcx40qlqql.lambda-url.us-east-1.on.aws/notes/${note._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      localStorage.removeItem("note");
      document.location.href = "/notes";
    } catch (error) {
      console.error(error);
    }
  };

  return note ? (
    <Flex
      direction={"column"}
      gap={6}
      p={6}
      w="100vw"
      minH="100vh"
      bg="gray.50"
      className="selectable"
    >
      {/* Breadcrumb for folder category */}
      {note.folder_category && (
        <Breadcrumb separator="|">
          {note.folder_category.map((category, index) => (
            <BreadcrumbItem key={index}>
              <BreadcrumbLink href="/folders">{category}</BreadcrumbLink>
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
      )}
      {/* Title and reminder */}
      <Flex direction={"column"} w="full" justifyContent="space-between">
        <Heading>{note.title}</Heading>
        {note.reminder_datetime && (
          <HStack color="purple.500">
            <Icon as={FiCalendar} />
            <Text fontWeight="bold" onClick={handleReminderNotification}>
              {new Date(note.reminder_datetime).toLocaleString()}
            </Text>
          </HStack>
        )}
      </Flex>

      {/* Content */}
      <Box
        w="full"
        p={4}
        bg="white"
        borderRadius="md"
        boxShadow="md"
        onClick={() => {
          navigator.clipboard.writeText(note.content);
        }}
      >
        <Flex justifyContent={"space-between"} alignItems={"center"} mb="2">
          <Text fontWeight="bold">Note</Text>
          <Icon as={GoCopy} />
        </Flex>
        <Text
          className="selectable"
          dangerouslySetInnerHTML={{ __html: note.content }}
        />
      </Box>

      {/* Images in grid */}
      {note.images && (
        <SimpleGrid columns={[1, 2]} spacing={4} w="full">
          {note.images.map((image, index) => (
            <Box key={index} borderRadius="md" overflow="hidden" boxShadow="md">
              <img src={image.content} alt={`Note Image ${index + 1}`} />
            </Box>
          ))}
        </SimpleGrid>
      )}
      {/* Summary */}
      {note.summary && (
        <Box w="full" p={4} bg="purple.50" borderRadius="md" boxShadow="md">
          <Flex
            justifyContent={"space-between"}
            alignItems={"center"}
            mb="2"
            onClick={() => {
              navigator.clipboard.writeText(note.text_from_images);
            }}
          >
            <Text fontWeight="bold">Description</Text>
            <Icon as={PiMagicWandBold} />
          </Flex>
          <Text>{note.summary}</Text>
        </Box>
      )}

      {/* Text from images */}
      {note.text_from_images && (
        <Box w="full" p={4} bg="gray.100" borderRadius="md" boxShadow="md">
          <Flex
            justifyContent={"space-between"}
            alignItems={"center"}
            mb="2"
            onClick={() => {
              navigator.clipboard.writeText(note.text_from_images);
            }}
          >
            <Text fontWeight="bold">Click to copy text</Text>
            <Icon as={GoCopy} />
          </Flex>
          <Text>{note.text_from_images}</Text>
        </Box>
      )}

      {/* Tags */}
      {note.tags && (
        <Flex w="full" wrap="wrap">
          {note.tags.map((tag, index) => (
            <Tag key={index} size="lg" m={1} colorScheme="purple">
              {tag}
            </Tag>
          ))}
        </Flex>
      )}

      {/* Buttons */}
      <Flex w="full" justifyContent="space-between" mt="auto">
        <Button
          colorScheme="gray"
          onClick={() => (document.location.href = "/notes")}
        >
          Back
        </Button>
        <Flex gap="2">
          <Button color={"red"} onClick={handleDeleteNote}>
            <MdDelete size={"20px"} />
          </Button>
          <Button
            leftIcon={<FiEdit />}
            colorScheme="blue"
            onClick={() => {
              localStorage.setItem("edit-note", JSON.stringify(note));
              document.location.href = "/note/edit";
            }}
          >
            Edit Note
          </Button>
        </Flex>
      </Flex>
    </Flex>
  ) : (
    <>loading</>
  );
};

export default NoteDisplay;
