import React, { useState, useEffect } from "react";
import {
  Box,
  Input,
  Text,
  VStack,
  Grid,
  GridItem,
  Flex,
  Tag,
  Image,
  useToast,
} from "@chakra-ui/react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import AddNoteButton from "./AddNoteButton";
import { useLocation } from "react-router-dom";
import FullPageLoader from "./FullPageLoader";

// API Gateway URL
const API_URL =
  "https://bwkgm6mfhx2fjgedkjkslvlcx40qlqql.lambda-url.us-east-1.on.aws/notes";

// Function to fetch notes from the API
const fetchNotes = async () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("token"),
  };
  const { data } = await axios.get(API_URL, { headers });
  return data;
};

// Function to create a new note
const createNote = async (newNote) => {
  const { data } = await axios.post(API_URL, newNote, {
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

const Folders = () => {
  const [search, setSearch] = useState("");
  const [folders, setFolders] = useState({});
  const queryClient = useQueryClient();
  const path = useLocation();

  // Fetch notes using React Query
  const { data: notes, isLoading, error } = useQuery(["notes"], fetchNotes);

  // Create note using React Query mutation
  const mutation = useMutation(createNote, {
    onSuccess: () => {
      queryClient.invalidateQueries(["notes"]); // Refetch notes after mutation
    },
  });

  // Group notes by folder_category (handling multiple categories per note)
  useEffect(() => {
    if (notes) {
      const tempFolders = {};
      for(const note of notes) {
        for(const folder of note.folder_category) {
          if (!tempFolders[folder]) {
            tempFolders[folder] = [];
          }
          tempFolders[folder].push(note);
        }
      }
      //remove duplicates
      const groupedNotes = Object.keys(tempFolders).reduce((acc, key) => {
        acc[key] = [...new Set(tempFolders[key])];
        return acc;
      }, {});
      setFolders(groupedNotes);
    }
  }, [notes]);

  const addTextNote = () => {
    const newNote = {
      title: `New Text Note ${notes ? notes.length + 1 : 1}`,
      content: `This is a new text note`,
      folder_category: ["Uncategorized"], // Assign to default folder
    };
    mutation.mutate(newNote);
  };

  const addImageNote = () => {
    const newNote = {
      title: `New Image Note ${notes ? notes.length + 1 : 1}`,
      content: `This is a new image note`,
      folder_category: ["Uncategorized"], // Assign to default folder
    };
    mutation.mutate(newNote);
  };

  // Handle folder click to save notes in localStorage
  const handleFolderClick = (folderName) => {
    const notesForFolder = folders[folderName] || [];
    localStorage.setItem("current_folder_name", folderName);
    localStorage.setItem(
      "current_folder_notes",
      JSON.stringify(notesForFolder)
    );
    // Optionally redirect to another page where the notes are displayed
    document.location.href = `/folder/${folderName}`;
  };

  const toast = useToast();

  useEffect(() => {
    if (error) {
      toast({
        title: "Error fetching notes.",
        description:
          "There was an error fetching notes. Please try to login again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      localStorage.removeItem("token");
      document.location.href = "/";
    }
  }, [error]);

  if (isLoading) return <FullPageLoader />;
  if (error) return <div>Error fetching notes: {error.message}</div>;

  return (
    <Box w="100vw" h="100vh" bg="gray.100" p={4}>
      {/* Top search bar */}
      {/* <Input
        size={"lg"}
        borderRadius={"2xl"}
        placeholder="Search folders..."
        mb={4}
        bg="white"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
      /> */}

      <Flex gap="2" flexWrap={"wrap"} mb="8">
        <Tag
          size="lg"
          variant={path?.pathname === "/notes" ? "solid" : "outline"}
          onClick={() => {
            document.location.href = "/notes";
          }}
          cursor={"pointer"}
          _hover={{ bg: "gray.200" }}
        >
          All
        </Tag>
        <Tag
          size="lg"
          variant={path?.pathname === "/folders" ? "solid" : "outline"}
          onClick={() => {
            document.location.href = "/folders";
          }}
          _hover={{ bg: "gray.200" }}
          cursor={"pointer"}
        >
          Categories
        </Tag>
      </Flex>

      {/* Grid displaying folders */}
      <Grid templateColumns="repeat(auto-fill, minmax(150px, 1fr))" gap={6}>
        {Object.entries(folders)
          .filter(([folderName]) =>
            folderName.toLowerCase().includes(search.toLowerCase())
          ) // Filtering based on search
          .map(([folderName, folderNotes]) => (
            <GridItem
              key={folderName}
              borderRadius="xl"
              textAlign="center"
              cursor="pointer"
              onClick={() => handleFolderClick(folderName)}
            >
              {/* Folder Icon */}
              <Image
                mx="auto"
                src="/folder.png"
                alt={folderName}
                boxSize="100px"
                mb={4}
              />
              {/* Folder Name */}
              <Text fontSize="md" fontWeight="bold">
                {folderName}
              </Text>
            </GridItem>
          ))}
      </Grid>

      <AddNoteButton onAddText={addTextNote} onAddImage={addImageNote} />

      {/* Loading and error handling for mutations */}
      {mutation.isLoading && <div>Creating note...</div>}
      {mutation.error && (
        <div>Error creating note: {mutation.error.message}</div>
      )}
    </Box>
  );
};

export default Folders;
