import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Icon,
  HStack,
  VStack,
  Box,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { FiFileText, FiX } from "react-icons/fi";
import { CiCamera } from "react-icons/ci";
import Modal from "react-modal";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

// API URL for note upload
const API_URL =
  "https://bwkgm6mfhx2fjgedkjkslvlcx40qlqql.lambda-url.us-east-1.on.aws/notes";

// API call to upload the note
const uploadNote = async (newNote) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("token"), // Include auth token
  };
  const { data } = await axios.post(API_URL, newNote, { headers });
  return data;
};

const AddNoteButton = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  const [images, setImages] = useState([]); // Store image URLs
  const [modalIsOpen, setIsOpen] = useState(false); // Control main modal state
  const [saveModalIsOpen, setSaveModalIsOpen] = useState(false); // Control save modal state
  const [comment, setComment] = useState(""); // Store user comment
  const [isLoading, setIsLoading] = useState(false); // Loading state for save button

  // Mutation for uploading the note
  const mutation = useMutation(uploadNote, {
    onSuccess: () => {
      queryClient.invalidateQueries(["notes"]);
      toast({
        title: "Note saved.",
        description: "Your note has been successfully saved.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: "Error saving note.",
        description: "There was an error saving your note. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false); // Reset loading state on error
    },
  });

  // Open the camera and handle image input
  const openCamera = (event) => {
    const files = event.target.files;
    const imagesArray = [...files].map((file) => URL.createObjectURL(file)); // Convert files to object URLs
    setImages((prevImages) => [...prevImages, ...imagesArray]); // Append new images to state
    setIsOpen(true); // Open the modal to preview
  };

  // Convert files to base64 before uploading
  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file); // Read file as data URL (base64)
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // Handle proceed click to save the note
  const handleProceed = async () => {
    setIsLoading(true); // Set loading state to true

    const base64Images = await Promise.all(
      images.map(async (imageUrl) => {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const content = await convertFileToBase64(blob);
        return {
          type: blob.type.split("/")[1],
          content: content,
        };
      })
    );

    const note = {
      content: comment, // Comment serves as the description
      images: base64Images, // Base64 encoded images
    };

    mutation.mutate(note); // Trigger the mutation to upload the note
  };

  // Close modal handler
  const closeModal = () => {
    setIsOpen(false);
  };

  // Open save modal
  const openSaveModal = () => {
    setSaveModalIsOpen(true);
  };

  // Close save modal
  const closeSaveModal = () => {
    setSaveModalIsOpen(false);
  };

  return (
    <>
      <ButtonGroup
        isAttached
        position="fixed"
        bottom="20px"
        left="50%"
        transform="translateX(-50%)"
        spacing={4}
        size={"lg"}
      >
        <input
          type="file"
          accept="image/*"
          capture="camera"
          multiple
          onChange={openCamera}
          style={{ display: "none" }}
          id="cameraInput"
        />
        {/* Left side button for text note */}
        <Button
          colorScheme="teal"
          onClick={() => {
            document.location.href = "/note/create"; // Redirect to AddTextNote page
          }}
        >
          <Icon as={FiFileText} />
        </Button>

        {/* Right side button for image note */}
        <Button
          colorScheme="purple"
          onClick={() => document.getElementById("cameraInput").click()}
        >
          <Icon as={CiCamera} />
        </Button>
      </ButtonGroup>

      {/* Modal for image preview carousel */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Preview"
        style={{
          overlay: { zIndex: 1000 },
          content: {
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            padding: "0",
            margin: "0",
            height: "97vh", // Full screen height
            width: "100vw", // Full screen width
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.95)",
          },
        }}
        ariaHideApp={false}
      >
        {/* Close button in the top-right corner */}
        <Box position="absolute" top="10px" right="10px">
          <Button
            colorScheme="red"
            onClick={closeModal}
            size="sm"
            variant="ghost"
          >
            <Icon as={FiX} />
          </Button>
        </Box>

        {images.length > 0 && (
          <VStack spacing={4} height="100%" justifyContent="center">
            <Carousel
              showArrows
              infiniteLoop
              autoPlay={false}
              selectedItem={0}
              showThumbs={false}
              dynamicHeight={false}
              showStatus={false}
              showIndicators={false}
              useKeyboardArrows
            >
              {images.map((image, index) => (
                <div key={index}>
                  <img
                    src={image}
                    alt={`Selected image ${index + 1}`}
                    style={{ maxHeight: "80vh", objectFit: "contain" }}
                  />
                </div>
              ))}
            </Carousel>

            {/* Button to add new images */}
            <HStack spacing={2}>
              <Button
                colorScheme="purple"
                onClick={() => document.getElementById("cameraInput").click()}
                leftIcon={<Icon as={CiCamera} />}
              >
                Add
              </Button>
              <Button colorScheme="blue" onClick={openSaveModal}>
                Proceed
              </Button>
            </HStack>
          </VStack>
        )}
      </Modal>

      {/* Modal for adding comments before proceeding */}
      <Modal
        isOpen={saveModalIsOpen}
        onRequestClose={closeSaveModal}
        contentLabel="Add Comments"
        style={{
          overlay: { zIndex: 1000 },
          content: {
            top: "40%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            width: "400px",
            padding: "20px",
            backgroundColor: "white",
            borderRadius: "8px",
          },
        }}
        ariaHideApp={false}
      >
        <VStack spacing={4}>
          <Textarea
            rows={5}
            placeholder="Any comments to remember? (Optional)"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <HStack spacing={4} justifyContent="flex-end">
            <Button colorScheme="red" onClick={closeSaveModal}>
              Cancel
            </Button>
            {/* Save button with loading state */}
            <Button
              colorScheme="blue"
              onClick={async () => {
                handleProceed();
                setTimeout(() => {
                  setIsLoading(false); // Reset loading state
                  setImages([]); // Clear images after saving
                  setComment(""); // Clear comment
                  closeSaveModal();
                  closeModal();
                  document.location.reload(); // Reload the page
                }, 3000);
              }}
              isLoading={isLoading}
            >
              Save
            </Button>
          </HStack>
        </VStack>
      </Modal>
    </>
  );
};

export default AddNoteButton;
