import React from "react";
import { LineWave } from "react-loader-spinner";
import { Box, Center } from "@chakra-ui/react";

const FullPageLoader = () => {
  return (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      // backgroundColor="lightblue"
    >
      <LineWave
        height="100"
        width="100"
        color="#4FD1C5"
        ariaLabel="loading"
        visible={true}
      />
    </Box>
  );
};

export default FullPageLoader;
