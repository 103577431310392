// TermsOfService.js
import React from "react";

const TermsOfService = () => {
  return (
    <div style={{ padding: "20px", textAlign: "center" }}>
      <h1>Terms of Service</h1>
      <p>
        You can view our Terms of Service below or{" "}
        <a
          href="https://rememberpad.s3.us-east-1.amazonaws.com/notes/RememberPad_Terms_of_Service.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          download the PDF
        </a>
        .
      </p>
      <iframe
        src="https://rememberpad.s3.us-east-1.amazonaws.com/notes/RememberPad_Terms_of_Service.pdf"
        width="100%"
        height="800px"
        style={{ border: "none" }}
        title="Terms of Service"
      ></iframe>
    </div>
  );
};

export default TermsOfService;
