// List of common words to ignore
const commonWords = [
  "the",
  "is",
  "and",
  "in",
  "of",
  "to",
  "a",
  "with",
  "for",
  "on",
  "it",
  "by",
  "an",
  "as",
  "at",
  "be",
  "this",
  "that",
  "which",
  "from",
  "but",
  "or",
  "are",
  "was",
  "were",
  "have",
  "has",
  "had",
  "does",
  "do",
  "did",
  "will",
  "shall",
  "should",
  "can",
  "could",
];

// Helper function to calculate the Levenshtein distance
const levenshteinDistance = (a, b) => {
  const matrix = Array.from({ length: b.length + 1 }, (_, i) => [i]).concat(
    Array.from({ length: a.length }, (_, i) => [i + 1])
  );

  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      matrix[j][i] =
        b[i - 1] === a[j - 1]
          ? matrix[j - 1][i - 1]
          : Math.min(
              matrix[j - 1][i - 1] + 1,
              matrix[j][i - 1] + 1,
              matrix[j - 1][i] + 1
            );
    }
  }

  return matrix[a.length][b.length];
};

// Helper function to get word similarity (returns a score between 0 and 1)
const getSimilarity = (word1, word2) => {
  const maxLen = Math.max(word1.length, word2.length);
  const distance = levenshteinDistance(
    word1.toLowerCase(),
    word2.toLowerCase()
  );
  return 1 - distance / maxLen;
};

// Function to score a note based on fuzzy matching and ignoring common words
const getNoteScore = (note, searchWords) => {
  let score = 0;

  // Helper function to check if a word matches any note field with fuzzy comparison
  const checkMatch = (text, word) => {
    const wordsInText = text?.toLowerCase().split(" ") || [];
    return wordsInText.some((noteWord) => getSimilarity(noteWord, word) >= 0.8);
  };

  searchWords.forEach((word) => {
    if (checkMatch(note.title, word)) score++;
    if (checkMatch(note.content, word)) score++;
    if (checkMatch(note.summary, word)) score++;
    if (checkMatch(note.text_from_images, word)) score++;
  });

  return score;
};

// Updated rankNotes function
export const rankNotes = (notes, searchQuery) => {
  if (!searchQuery) return notes; // If no search input, return all notes

  // Split search query into words, remove common words and empty strings
  const searchWords = searchQuery
    .toLowerCase()
    .split(" ")
    .filter((word) => word && !commonWords.includes(word.toLowerCase()));

  // Rank the notes based on their score
  const rankedNotes = notes
    .map((note) => ({
      ...note,
      score: getNoteScore(note, searchWords), // Add a score property to each note
    }))
    .filter((note) => note.score > 0) // Filter out notes with a score of 0
    .sort((a, b) => b.score - a.score) // Sort by score in descending order
    .slice(0, 10); // Limit to top 10 notes

  return rankedNotes;
};
