import React, { useEffect } from "react";
import {
  Box,
  Image,
  Text,
  Flex,
  VStack,
  HStack,
  Icon,
  Link,
} from "@chakra-ui/react";
import { FaCamera, FaBell, FaSearch } from "react-icons/fa"; // Import icons from React Icons
import { GoogleLogin } from "@react-oauth/google";
import axios from "axios";

function Home() {
  useEffect(() => {
    if (localStorage) {
      const token = localStorage.getItem("token");
      if (token) {
        document.location.href = "/notes";
      }
    }
  }, []);
  
  return (
    <Box py={5} px="2">
      {/* Header with App Logo and App Name */}
      <VStack spacing={4}>
        <Box
          boxShadow="rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px"
          overflow="hidden"
          p={6}
          width="300px"
          textAlign="left"
          bg="gray.100"
        >
          <HStack spacing={4}>
            <Image
              borderRadius={"md"}
              src="/android-chrome-192x192.png"
              height={"50px"}
              width={"50px"}
            />
            <Box>
              <Text fontSize="xl" mb={2}>
                Remember Pad
              </Text>
            </Box>
          </HStack>
        </Box>
      </VStack>

      {/* Features Section */}
      <Box>
        <Box px="5" my={4}>
          <GoogleLogin
            onSuccess={async (response) => {
              try {
                const token = response.credential;
                // Send the token to the backend to verify and get user details
                const res = await axios.post(
                  "https://bwkgm6mfhx2fjgedkjkslvlcx40qlqql.lambda-url.us-east-1.on.aws/google",
                  { token }
                );
                console.log("User details:", res.data);
                localStorage.setItem("token", res.data.token);
                document.location.href = "/notes";
              } catch (error) {
                console.error("Error during authentication:", error);
              }
            }}
            onError={() => {
              console.log("Login Failed");
            }}
          />
        </Box>

        <Flex gap={6} px="2" wrap="wrap">
          {/* Feature 1: Snap Stuff to Remember */}
          <Box
            overflow="hidden"
            p={6}
            w="full"
            textAlign="left"
            bg="gray.100"
            boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
          >
            <Flex align="center" mb={2}>
              <Icon as={FaCamera} w={8} h={8} color="teal.500" />
              <Text fontSize="lg" ml={3}>
                Snap Stuff to Remember
              </Text>
            </Flex>
            <Text>
              Capture notes or images on the go and never forget important
              details.
            </Text>
          </Box>

          {/* Feature 2: Auto Reminders */}
          <Box
            w="full"
            boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
            overflow="hidden"
            p={6}
            textAlign="left"
            bg="gray.100"
          >
            <Flex align="center" mb={2}>
              <Icon as={FaBell} w={8} h={8} color="teal.500" />
              <Text fontSize="lg" ml={3}>
                Auto Reminders
              </Text>
            </Flex>
            <Text>
              Set automatic reminders based on the content you've captured,
              helping you stay organized.
            </Text>
          </Box>

          {/* Feature 3: Auto Categorization & Search */}
          <Box
            boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
            overflow="hidden"
            p={6}
            w="full"
            textAlign="left"
            bg="gray.100"
          >
            <Flex align="center" mb={2}>
              <Icon as={FaSearch} w={8} h={8} color="teal.500" />
              <Text fontSize="lg" ml={3}>
                Auto Categorization & Search
              </Text>
            </Flex>
            <Text>
              Intelligent search and categorization, even indexing the content
              in your images for easy access.
            </Text>
          </Box>
        </Flex>
      </Box>

      {/* Footer with Links to Privacy Policy and Terms of Service */}
      <Box mt={8} textAlign="center">
        <Text fontSize="sm" color="gray.600">
          <Link href="/privacy-policy" color="teal.500" mr={4}>
            Privacy Policy
          </Link>
          <Link href="/tos" color="teal.500">
            Terms of Service
          </Link>
        </Text>
      </Box>
    </Box>
  );
}

export default Home;
