import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Quill editor styles

const RichTextEditor = ({ value, onChange }) => {
  return (
    <ReactQuill
      placeholder="Enter note content..."
      theme="snow"
      value={value}
      onChange={onChange}
      style={{ height: "100%", backgroundColor: "white", borderRadius: "8px" }}
    />
  );
};

export default RichTextEditor;
