import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  VStack,
  Flex,
  Input,
  useToast,
  Image,
  IconButton,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  HStack,
} from "@chakra-ui/react";
import { FiCamera, FiTrash } from "react-icons/fi";
import RichTextEditor from "./RichTextEditor"; // Custom rich text editor component
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useLocation } from "react-router-dom";

const API_URL =
  "https://bwkgm6mfhx2fjgedkjkslvlcx40qlqql.lambda-url.us-east-1.on.aws/notes";

const createNote = async (newNote) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("token"),
  };
  const { data } = await axios.post(API_URL, newNote, { headers });
  return data;
};

const ShareHandler = () => {
  const location = useLocation();

  const queryClient = useQueryClient();
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure(); // Chakra UI modal control

  const [content, setContent] = useState("");
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false); // New loading state

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file); // Read file as data URL (base64)
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleImageSelection = async (event) => {
    const files = event.target.files;
    if (images.length + files.length > 3) {
      toast({
        title: "Limit reached.",
        description: "You can only add up to 3 images.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const tempImages = [];
    for (let file of files) {
      if (file.size > 5 * 1024 * 1024) {
        toast({
          title: "File too large.",
          description: "Each image must be smaller than 2 MB.",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      const base64 = await convertFileToBase64(file);
      tempImages.push({
        type: file.type.split("/")[1],
        content: base64,
      });
    }
    setImages((prevImages) => [...prevImages, ...tempImages]);
  };

  const mutation = useMutation(createNote, {
    onMutate: () => setLoading(true), // Start loading on mutation start
    onSuccess: () => {
      queryClient.invalidateQueries(["notes"]);
      toast({
        title: "Note saved.",
        description: "Your note has been successfully saved.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false); // Stop loading on success
    },
    onError: () => {
      toast({
        title: "Error saving note.",
        description: "There was an error saving your note. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false); // Stop loading on error
    },
  });

  const handleSave = () => {
    if (!content.trim()) {
      toast({
        title: "Incomplete note.",
        description: "Content are required.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    mutation.mutate({ content, images });
  };

  const handleDeleteImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    onOpen(); // Open Chakra UI modal
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const title = urlParams.get("name");
    const text = urlParams.get("description");
    const url = urlParams.get("link");

    if (title || text || url) {
      console.log("Shared content received:", { title, text, url });
      setContent(
        `${title ? title + "\n" : ""}${text ? text + "\n" : ""}${
          url ? url + "\n" : ""
        }`
      );
    }
  }, [location]);

  return (
    <Flex direction="column" w="100vw" minH="100vh" p={4} bg="gray.100">
      <Box flex="1" bg="white" borderRadius="8px" mb={4}>
        <RichTextEditor value={content} onChange={setContent} />
      </Box>

      <Flex mb={4} alignItems="center" justifyContent={"center"}>
        <Input
          type="file"
          accept="image/*"
          capture="camera"
          onChange={handleImageSelection}
          style={{ display: "none" }}
          id="cameraInput"
        />
        <Button
          colorScheme="purple"
          leftIcon={<FiCamera />}
          onClick={() => document.getElementById("cameraInput").click()}
        >
          Add Image
        </Button>
      </Flex>

      {/* Display images in small boxes */}
      <SimpleGrid columns={3} spacing={2}>
        {images.map((image, index) => (
          <Box key={index} position="relative">
            <Image
              src={image.content}
              alt={`Image ${index + 1}`}
              boxSize="100px"
              objectFit="cover"
              borderRadius="md"
              onClick={() => handleImageClick(image)}
              cursor="pointer"
            />
            <IconButton
              icon={<FiTrash />}
              size="sm"
              position="absolute"
              top="0"
              right="0"
              colorScheme="red"
              onClick={() => handleDeleteImage(index)}
            />
          </Box>
        ))}
      </SimpleGrid>

      <HStack spacing={4} mt={4}>
        <Button
          bg="gray.300"
          onClick={() => (document.location.href = "/notes")}
          w="100%"
        >
          Back to Home
        </Button>
        <Button
          colorScheme="blue"
          onClick={async () => {
            handleSave();
            setTimeout(() => {
              document.location.href = "/notes";
            }, 3000);
          }}
          w="100%"
          isLoading={loading} // Add loading state here
        >
          Save Note
        </Button>
      </HStack>

      {/* Modal for full image preview using Chakra UI */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent maxW="90%">
          <ModalBody p="0">
            <Image
              src={selectedImage?.content}
              alt="Selected Image"
              maxHeight="80vh"
              objectFit="contain"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default ShareHandler;
